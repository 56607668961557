import axios from 'axios'
export default() => {

    let baseURL = 'https://servicios02.goodhope.org.pe/hope-service/';
    //let baseURL = '';
    if (process.env.NODE_ENV === 'development') {
        //baseURL = 'http://localhost:8080/hope-service/';
    }
    return axios.create({
        baseURL: baseURL,
        withCredentials: false,
        crossdomain:true,
        headers:{
            'Access-Control-Allow-Origin':'*',
            'Authorization': localStorage.getItem("hope") || ''
        }
    })
}
