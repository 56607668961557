import HopeService from '@/service/hope'
export default{
    getToken(usuario, contrasena){
        return HopeService().post('login/service', {user:usuario, password:contrasena});
    },
    getUsuario(){
        return HopeService().get('control/persona');
    },
    setUsuario(usuario){
        return HopeService().post('control/persona', usuario);
    },
    setNuevoUsuario(usuario){
        return HopeService().post('control/registro', usuario);
    },
    recuperarContrasena(mail){
        return HopeService().get('recover/solicitar/'+mail);
    },
    updatePassword(formulario){
        return HopeService().post('recover/password', formulario);
    }

}